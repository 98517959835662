<template>
  <b-modal hide-header hide-header-close v-model="show" centered no-close-on-backdrop no-close-on-esc v-if="validations.length != 0">

  <b-list-group flush>
    <b-list-group-item v-for="(validation, key) in validations" :key="key">{{validation.validationMessage}}</b-list-group-item>
  </b-list-group>

    <template #modal-footer>
      <b-button size="sm" variant="secondary"  @click="close">
        {{ $t('CLOSE') }}
      </b-button>
    </template>
  </b-modal> 
</template>
<script>
export default {
  data() {
    return {
      show: true
    };
  },
  methods: {
    async close()
    {
      await this.$store.dispatch("errors/removeValidations");
      this.show = false;
    },
    getVariant(validation)
    {
      switch(validation.status)
      {
        case "Error": return 'danger';
        case "Warning": return "warning";
        default: return 'info';
      }
    }
  },
  computed: {
    validations: function () {
      let validations = this.$store.state.errors.validations;
      const ids = [...new Set(validations.map(x => x.id))];
      const distinct = ids.map(id => validations.find(x => x.id === id));
      return distinct;
    }
  },
  watch: {
    validations: function () {
      //this.show = this.validations.length != 0;
    }
  },
  mounted: function() {
  },
};
</script>
<style></style>